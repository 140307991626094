<template>
  <div class="max-w-full mx-auto px-4 md:px-6 lg:max-w-7xl lg:px-8 relative">
    <slot />
  </div>
</template>
<script>
export default {};
</script>
<style lang="">
</style>
